

import React, {useState} from 'react'
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";


import axios from 'axios';

/*
export default () => {
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setError] = useState('');

    /*
const submitValue = async() => {
    
    /*
    let url = "https://sandbox.skillz.com/oauth/token";
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": "Basic ODYyNjo="
    }

    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', user);
    params.append('password', password);
    params.append('scope', 'sdk');

    let config_post = {
      headers: headers,
      method: "POST"
    };
    let post;

    try {
      console.log("Login...")
      post = await axios.post(url,params, {
        headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": "Basic ODYyNjo="
        }
      })
      console.log(post)
      //window.location.replace("/auth");
    

      }
    catch(error)
    {
      console.log(error.message)
      console.log("error")
      setError(error.message)
      console.log(errorMessage)
    }

    


    const auth_lambda_at_edge_body = {
      "password": password,
      "name": user
    }
    
    let config_post = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      method: "POST",
      data: JSON.stringify(auth_lambda_at_edge_body),
    };

    try {
      console.log("Auth...")
      const auth_post = await axios("/auth",config_post,JSON.stringify(auth_lambda_at_edge_body))
      console.log(auth_post)
      window.location.replace("/");
    }
    catch(error)
    {
      console.log(error.message)
      console.log("error")
      setError(error.message)
      console.log(errorMessage)
    }
}

*/

export default function LogIn() {

  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setError] = useState('');

  const handleSubmit = async (event) => {
    
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    /*
    const auth_lambda_at_edge_body = {
      token: data.get("token")
    }
    */
    
    const auth_lambda_at_edge_body = {
      "password": data.get("password"),
      "name": data.get("username")
    }
    
    let config_post = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      method: "POST",
      data: JSON.stringify(auth_lambda_at_edge_body),
    };

    try {
      console.log("Auth...")
      const auth_post = await axios("/auth",config_post,JSON.stringify(auth_lambda_at_edge_body))
      console.log(auth_post)
      window.location.replace("/");
    }
    catch(error)
    {
      console.log(error.message)
      console.log("error")
      setError(error.message)
      console.log(errorMessage)
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{  
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Log In
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="string"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="token"
            label="token"
            id="token"
            autoComplete="token"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Log In
          </Button>
        </Box>
      </Box>
    </Container>
  );
}